<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card class="cardClassDetailInfo" :noHeader="true">
            <template slot="card-detail">
              <div class="col-3">
                <c-label-text title="사업장" :value="chemData.plantName"></c-label-text>
              </div>
              <div class="col-3">
                <c-label-text title="자재명" :value="chemData.materialName"></c-label-text>
              </div>
              <div class="col-3">
                <c-label-text title="자재코드" :value="chemData.materialCd"></c-label-text>
              </div>
              <div class="col-3">
                <c-label-text title="화학자재일련번호" :value="chemData.mdmChemMaterialId"></c-label-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-12 col-lg-12">
          <c-card title="유해화학물질(PSM) 정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      (주)
                    </div>
                    <div class="tooltipCustom">
                    ① 유해․위험물질은 제출대상 설비에서 제조 또는 취급하는 모든 화학물질을 기재합니다.<br>
                    ② 증기압은 상온에서의 증기압을 말합니다.<br>
                    ③ 부식성 유무는 있으면 ○, 없으면 ×로 표시합니다.<br>
                    ④ 이상반응 여부는 그 물질과 이상반응을 일으키는 물질과 그 조건(금수성 등)을 표시하고 필요시 별도로 작성합니다.<br>
                    ⑤ 노출기준에는 시간가중평균노출기준(TWA)을 기재합니다.<br>
                    ⑥ 독성치에는 LD50(경구, 쥐), LD50(경피, 쥐 또는 토끼) 또는 LC50(흡입, 4시간 쥐)을 기재합니다.
                    </div>
                  </q-tooltip>
                </q-btn>
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="psmData"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveHazard"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-text
                  suffix="%"
                  :editable="editable"
                  label="폭발한계상한(%)"
                  name="explUpLimit"
                  v-model="psmData.explUpLimit">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  suffix="%"
                  :editable="editable"
                  label="폭발한계하한(%)"
                  name="explLowLimit"
                  v-model="psmData.explLowLimit">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  suffix="℃"
                  :editable="editable"
                  label="인화점(℃)"
                  name="flashingPoint"
                  v-model="psmData.flashingPoint">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  suffix="℃"
                  :editable="editable"
                  label="발화점(℃)"
                  name="ignitionPoint"
                  v-model="psmData.ignitionPoint">
                </c-text>
              </div>
              <div class="col-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="노출기준"
                  name="exposureLimit"
                  v-model="psmData.exposureLimit">
                </c-textarea>
              </div>
              <div class="col-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="독성치"
                  name="toxicValue"
                  v-model="psmData.toxicValue">
                </c-textarea>
              </div>
              <div class="col-3">
                <c-text
                  suffix="mmHg"
                  :editable="editable"
                  label="증기압(20℃,mmHg)"
                  name="vaporPressure"
                  v-model="psmData.vaporPressure">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  suffix="Nm/kg"
                  :editable="editable"
                  label="일일취급량(kg)"
                  name="dailyUsage"
                  v-model="psmData.dailyUsage">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  suffix="Nm/kg"
                  :editable="editable"
                  label="저장량(kg)"
                  name="stockage"
                  v-model="psmData.stockage">
                </c-text>
              </div>
              <div class="col-3">
                <c-dept type="edit" label="관리부서" name="deptCd" v-model="psmData.deptCd" />
              </div>
              <div class="col-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="이상반응유무"
                  name="extraReaction"
                  v-model="psmData.extraReaction">
                </c-textarea>
              </div>
              <div class="col-6">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="3"
                  label="부식성유무"
                  name="corrosivenessFlag"
                  v-model="psmData.corrosivenessFlag">
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-psm',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        mdmChemReviewId: '',  // 화학자재 사전검토 일련번호
        materialCd: '',  // 자재코드
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        storagePlace: '',  // 보관장소
        productCategoryCd: null,  // 제품구분
        ghsFlag: 'Y', // GHS 분류대상
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        specialCtype: null,  // 특별관리물질 C
        specialRtype: null,  // 특별관리물질 R
        specialMtype: null,  // 특별관리물질 M
        boilingPoint: null,
        hrCodeGrade: null,
        maleficenceForm: null,
        maleficenceType: null,
        maleficenceGrade: null,
        maleficenceValue: '',
        dayVolume: '',  // 하루취급량
        dayVolumeUnit: null,  // 하루취급량 단위
        rcode: '',  // 위험문구(R-code)
        hcode: '',  // 유해위험문구(H-code)
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveUrl: '',
      detailUrl: '',
      mappingType: 'POST',
      editable: true,
      isSave: false,
      updateMode: false,
      psmData: {
        psiHazardMaterialId: '',  // 유해위험물질 일련번호
        mdmChemMaterialId: '',  // 화학자재 일련번호
        plantCd: this.popupParam.plantCd,  // 사업장코드
        deptCd: '',  // 관리부서
        explUpLimit: '',  // 폭발한계상한(vol%)
        explLowLimit: '',  // 폭발한계하한(vol%)
        flashingPoint: '',  // 인하점(℃)
        ignitionPoint: '',  // 발화점(℃)
        vaporPressure: '',  // 증기압(20℃)
        extraReaction: '',  // 이상반응유무(내용기술)
        corrosivenessFlag: '',  // 부식성유무
        dailyUsage: '',  // 일 사용량
        stockage: '',  // 저장량
        remarks: '',  // 비고
        exposureLimit: '',  // 노출기준TWA
        toxicValue: '',  // 독성치
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'psmFlag.watch'() {
      this.getDetail();
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.chem.hazard.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.hazard.insert.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmChemMaterialId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {mdmChemMaterialId: this.popupParam.mdmChemMaterialId, plantCd: this.popupParam.plantCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if (_result.data.psiHazardMaterialId) {
            this.$_.extend(this.psmData, _result.data);
          } else {
            this.psmData = {
              psiHazardMaterialId: '',  // 유해위험물질 일련번호
              mdmChemMaterialId: this.popupParam.mdmChemMaterialId,  // 화학자재 일련번호
              plantCd: this.popupParam.plantCd,  // 사업장코드
              deptCd: '',  // 관리부서
              explUpLimit: '',  // 폭발한계상한(vol%)
              explLowLimit: '',  // 폭발한계하한(vol%)
              flashingPoint: '',  // 인하점(℃)
              ignitionPoint: '',  // 발화점(℃)
              vaporPressure: '',  // 증기압(20℃)
              extraReaction: '',  // 이상반응유무(내용기술)
              corrosivenessFlag: '',  // 부식성유무
              dailyUsage: '',  // 일 사용량
              stockage: '',  // 저장량
              remarks: '',  // 비고
              exposureLimit: '',  // 노출기준TWA
              toxicValue: '',  // 독성치
            }
          }
        },);
      }
    },
    saveHazard() {
      if (this.psmData.psiHazardMaterialId) {
        this.mappingType = 'PUT';
      } else {
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.psmData.regUserId = this.$store.getters.user.userId;
              this.psmData.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
  }
};
</script>